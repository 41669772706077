import { configPrepare } from "@/helpers/vue-good-table"
import { translateOtaName } from "@/helpers/common"
import { isSharedInventoryEnabled } from "@/helpers/organization"
import { i18n } from "@/config"

const defaultTableClasses = {
  tdClass: "logs-table-td",
  thClass: "logs-table-th"
}

export const i18nPrefix = "logs.table.columns"

const fields = [
  "created_at",
  {
    field: "type",
    formatFn: type => i18n.t(`logs.inventory_log.types.${type}`)
  },
  isSharedInventoryEnabled() ? "inventory_group_name" : "shop_name",
  "car_class_names",
  {
    field: "initiator_name",
    formatFn: ({ title, translate }) => (translate ? translateOtaName(title) : title)
  },
  {
    field: "ota_names",
    formatFn: ota_names => ota_names.map(name => translateOtaName(name)).join(", ") || "—"
  },
  "date",
  "text"
]

export const columns = () => configPrepare(fields, { i18nPrefix, defaults: defaultTableClasses, backendSort: false })
