import CreateLogsStore from "@/config/store/logs"

import { currentDateInTimeZone } from "@/helpers/common"
import { add as dateAdd } from "date-fns"

const today = currentDateInTimeZone()

const BASE_URI = "/inventory_logs"
const dateRange = [today, dateAdd(today, { months: 1 })]

export default CreateLogsStore(BASE_URI, dateRange)
